export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;
export const FILTER_API_ENDPOINT = process.env.REACT_APP_FILTER_API_ENDPOINT;
export const UTILITY_API_ENDPOINT =
  process.env.REACT_APP_UTILITIES_API_ENDPOINT;

export const baseEndpoint = api => API_ENDPOINT + api;
export const authBaseEndpoint = api => AUTH_API_ENDPOINT + api;
export const filterBaseEndpoint = api => FILTER_API_ENDPOINT + api;
export const commonUtilitiesEndpoint = api => UTILITY_API_ENDPOINT + api;
// Subcriptions Endpoints

export const getAllSubscriptionPlansApi = () =>
  baseEndpoint(
    '/subscriptionplan/getAll?page=0&count=10&sort=ASC&field=subscriptionplanid'
  );

export const createSubscriptionPlanApi = () =>
  baseEndpoint('/subscriptionplan/createsubscriptionplan');

export const getAllApplicationsApi = () =>
  baseEndpoint('/subscriptionplan/getallapplications');

export const jobsApi = () => baseEndpoint('/companyData/jobs');

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('/auth/signin');

export const jobAnalysisApi = () => baseEndpoint('/job/all');

export const getCompanyApi = userEmail =>
  commonUtilitiesEndpoint('/user/details/COMPANY_STUDENT_APP/' + userEmail);

// Jobs Endpoints

// * jobId -> jobDescriptionId || publishedJobId -> jobPublishId

export const createJobApi = (params = '') =>
  commonUtilitiesEndpoint('/create/job' + params);

export const editJobApi = (id = '') =>
  commonUtilitiesEndpoint('/edit/job/' + id);

export const getFileUploadApi = (id = '') =>
  commonUtilitiesEndpoint(`/job/${id}/attach/file`);

export const getDeleteFileApi = (jobId = 0, fileId = 0) =>
  commonUtilitiesEndpoint(`/job/${jobId}/file/${fileId}`);

export const publishJobApi = (params = '') =>
  baseEndpoint('/publish/jobposting' + params);

export const deleteJobApi = (id = '') => baseEndpoint('/job/delete/' + id);

export const closeJobApi = (id = '') =>
  baseEndpoint('/close/published/job/' + id);

export const getJobsApi = (id = '') =>
  commonUtilitiesEndpoint('/saved/job/all' + id);

export const getClosedJobsApi = (params = '') =>
  baseEndpoint('/closed/published/jobs' + params);

export const getClosedJobDetailsApi = (jobPublishId, params = '') =>
  baseEndpoint(`/closed/published/jobs/${jobPublishId}/details` + params);

export const getPublishedJobsApi = (params = '', compnayId) =>
  commonUtilitiesEndpoint(`/job/all` + params);

export const getJobByIdApi = (id = '') => commonUtilitiesEndpoint('/job/' + id);

export const getPublishedJobByIdApi = (id = '') =>
  baseEndpoint('/published/jobposting/' + id);

export const getJobPublishUniversityApi = () =>
  filterBaseEndpoint('/jobposting/university/list');

export const getJobPublishGraduationLevelApi = () =>
  filterBaseEndpoint('/jobposting/graduationLevel/list');

export const getJobPublishTargetPostingApi = () =>
  filterBaseEndpoint('/jobposting/target/list');

export const getJobApplicationsApi = (jobPublishedId, params = '') =>
  baseEndpoint(`/published/job/${jobPublishedId}/applications/all` + params);

export const getJobRecommendStudentsApi = (publishedJobId, params = '') =>
  baseEndpoint(
    `/published/jobposting/${publishedJobId}/recommend/students` + params
  );

export const getJobRecommendStudentsDetailsApi = (
  publishedJobId,
  universityId,
  params = ''
) =>
  baseEndpoint(
    `/published/jobposting/${publishedJobId}/university/${universityId}/recommend/students/details` +
      params
  );

export const getJobInterviewResultsApi = (publishedJobId, params = '') =>
  baseEndpoint(
    `/published/jobposting/${publishedJobId}/interview/results` + params
  );

export const getJobInterviewsApi = (publishedJobId, params = '') =>
  baseEndpoint(
    `/published/jobposting/${publishedJobId}/interview/invites/list` + params
  );

export const sendInterviewInviteApi = (params = {}) =>
  baseEndpoint(
    `/published/job/send/interview/invite/${params.jobPublishId}/${params.jobApplicationId}`
  );

export const updateInterviewInviteApi = (interviewInviteId, params = '') =>
  baseEndpoint(
    `/published/jobposting/update/interview/result/${interviewInviteId}` +
      params
  );

export const rescheduleInterviewInviteApi = (interviewInviteId, params = '') =>
  baseEndpoint(
    `/published/jobposting/reschedule/interview/invites/${interviewInviteId}` +
      params
  );

export const rejectJobApplicationApi = (params = '') =>
  baseEndpoint('/job/interview-application/' + params);

export const getJobOfferLettersApi = (publishedJobId, params = '') =>
  baseEndpoint(
    `/published/jobposting/${publishedJobId}/joboffer/list` + params
  );

export const sendOfferLetterApi = (publishedJobId, params = '') =>
  baseEndpoint(
    `/published/jobposting/${publishedJobId}/interview/results/issue/offer` +
      params
  );

export const getCoursesForJobApi = (params = '') =>
  baseEndpoint('/jobs/interview-invites' + params);

export const saveCoursesForJobApi = (params = '') =>
  baseEndpoint('/course/assign' + params);

// Seminars API
export const createSeminarApi = (params = '') =>
  commonUtilitiesEndpoint('/seminar');

export const editSeminarApi = (id = '') =>
  commonUtilitiesEndpoint('/edit/seminar/' + id);

export const deleteSeminarApi = (id = '') =>
  commonUtilitiesEndpoint('/seminar/' + id);

export const publishSeminarApi = (params = '') =>
  baseEndpoint('/publish/seminarPosting' + params);

export const getSavedSeminarsApi = (params = '') =>
  commonUtilitiesEndpoint('/saved/seminar/findall' + params);
export const getSavedSeminarDetailsApi = (id = '') =>
  commonUtilitiesEndpoint('/seminar/' + id);

export const getPublishedSeminarByIdApi = id =>
  baseEndpoint('/published/seminarPosting/' + id);

export const getPublishedSeminarsApi = (params = '') =>
  baseEndpoint('/published/seminarPosting' + params);

export const getClosedSeminarsApi = (params = '') =>
  baseEndpoint('/closed/seminar/list' + params);

export const publishSeminarPostingApi = (params = '') =>
  baseEndpoint('/seminar/publish' + params);

export const getRegisteredSeminarApplicationsApi = (
  publishedSeminarId,
  params = ''
) =>
  baseEndpoint(
    `/published/seminar/${publishedSeminarId}/registered/students` + params
  );

export const getAttendenceSeminarApplicationsApi = (
  publishedSeminarId,
  params
) =>
  baseEndpoint(
    `/published/seminar/${publishedSeminarId}/students/attendance/list` + params
  );

export const updateAttendenceSeminarApplicationsApi = () =>
  baseEndpoint('/published/seminar/student/update/attendance');

export const publishSavedSeminarApi = (params = '') =>
  baseEndpoint('/seminar/publish' + params);

export const updateSeminarApi = (id = '') =>
  baseEndpoint(`/published/seminarPosting/${id}/DISABLED`);

export const getSeminarQuestions = (id = '') =>
  baseEndpoint('/seminar/questions/' + id);

export const getSeminarPublishUniversityApi = () =>
  filterBaseEndpoint('/jobposting/university/list');

export const getSeminarPublishGraduationLevelApi = () =>
  filterBaseEndpoint('/jobposting/graduationLevel/list');

export const getSeminarPublishTargetPostingApi = () =>
  filterBaseEndpoint('/seminarPosting/target/list');

// Internship API
export const createInternshipApi = (companyId, params = '') =>
  commonUtilitiesEndpoint(`/company/${companyId}/internship/create` + params);

export const editInternshipApi = (companyId = '', id = '') =>
  commonUtilitiesEndpoint(`/company/${companyId}/internship/edit/` + id);

export const deleteInternshipApi = (params = '') =>
  baseEndpoint('/internship/delete' + params);

export const getSavedInternshipsApi = (companyId, params = '') =>
  commonUtilitiesEndpoint(`/company/${companyId}/saved/internships` + params);

export const getSavedInternshipDetailsApi = (companyId, id, params = '') =>
  commonUtilitiesEndpoint(`/company/${companyId}/internship/${id}` + params);

export const getPublishedInternshipsApi = (params = '') =>
  baseEndpoint('/published/internshipPosting' + params);

export const getClosedInternshipsApi = (params = '') =>
  baseEndpoint('/internship/all' + params);

export const publishInternshipPostingApi = (params = '') =>
  baseEndpoint('/internship/publish' + params);

export const getRegisteredInternshipApplicationsApi = (params = '') =>
  baseEndpoint('/internship/applications/all' + params);

export const getAttendenceInternshipApplicationsApi = (params = '') =>
  baseEndpoint('/internship/applications/all' + params);

export const updateAttendenceInternshipApplicationsApi = (id = '') =>
  baseEndpoint('/internship-application/update/' + id);

export const publishSavedInternshipApi = (params = '') =>
  baseEndpoint('/internship/publish' + params);

export const updateInternshipApi = (id = '') =>
  baseEndpoint('/internship/update/' + id);

export const publishInternshipApi = (params = '') =>
  baseEndpoint('/publish/internshipPosting' + params);

//Internship Request Api
export const editInternshiRequestpApi = (id = '') =>
  baseEndpoint('/internship/update/' + id);

export const getSelectedCandidateApi = (params = '') =>
  baseEndpoint('/internship/selected' + params);

export const sendInternshipOfferLetterApi = (id = '') =>
  baseEndpoint('/internship/offer' + id);

// Manage University Api
export const sendGrantApi = (id = '') =>
  baseEndpoint('/scholarship/grant' + id);

export const viewDetailsApi = (params = '') =>
  baseEndpoint('/recommendations/all' + params);

export const denyApi = (id = '') => baseEndpoint('/scholarship/deny' + id);

export const denyRecommendationApi = (id = '') =>
  baseEndpoint('/recommendation/deny' + id);

export const denyInternshipApi = (id = '') =>
  baseEndpoint('/request-internship/deny' + id);

export const denyJobForUniApi = (id = '') =>
  baseEndpoint('/jobForUniversity/deny' + id);

export const denySeminarApi = (id = '') =>
  baseEndpoint('/request-seminar/deny' + id);

export const holdApi = (id = '') => baseEndpoint('/recommendation/hold' + id);

export const holdInternshipApi = (id = '') =>
  baseEndpoint('/request-internship/hold' + id);

export const holdJobForUniApi = (id = '') =>
  baseEndpoint('/jobForUni/hold' + id);

export const holdRecommendationApi = (id = '') =>
  baseEndpoint('/request-seminar-recommendation/hold' + id);

export const acceptInternshipApi = (id = '') =>
  baseEndpoint('/request-internship/accept' + id);

export const acceptJobForUniApi = (id = '') =>
  baseEndpoint('/jobForUni/accept' + id);

export const acceptSeminarApi = (id = '') =>
  baseEndpoint('/request-seminar-recommendation/accept' + id);

export const sendGrantRecieptApi = (id = '') =>
  baseEndpoint('/scholarship/reciept-grant' + id);

export const editSeminarRequestpApi = (id = '') =>
  baseEndpoint('/seminar-recommend/update/' + id);
export const editJobRequestpApi = (id = '') =>
  baseEndpoint('/jobForUni/update/' + id);

export const sendJobForUniOfferLetterApi = (id = '') =>
  baseEndpoint('/jobForUni/offerLetter' + id);

export const sendJobForUniInvitationApi = (id = '') =>
  baseEndpoint('/jobForUni/invitation' + id);
export const sendJobInterviewInviteApi = (params = '') =>
  baseEndpoint('/jobForUni/interview-invite' + params);

// Handshake Api
export const sendHandshakeRequestApi = (id = '') =>
  baseEndpoint('/handshake/request' + id);
export const ShareDocumentApi = (params = '') =>
  baseEndpoint('/handshake/shareDocument' + params);

// Request Donation
export const sendDonationGrantRecieptApi = (id = '') =>
  baseEndpoint('/requestDonation/reciept-grant' + id);

export const denyDonationApi = (id = '') =>
  baseEndpoint('/requestDonation/deny' + id);

export const HoldDonationApi = (id = '') =>
  baseEndpoint('/requestDonation/hold' + id);

export const getManagedSavedSeminarsApi = (params = '') =>
  baseEndpoint('/seminar/all' + params);
